import {
  fetchBidOfferAffMixPage,
  handleBidOfferAffMixAdd,
  handleBidOfferAffMixEdit,
  handleBidOfferAffMixDel,
  fetchBidOfferAffMixDetails,
} from 'api/offer/bidOfferAffMix';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';
import { myMixin } from '@/mixins/mixins.js';
import { mapState, mapActions } from 'vuex';

export default {
  mixins: [myMixin],
  components: {
    GroupSelect,
    Pagination,
  },
  data() {
    var validateNumber = (rule, value, callback) => {
      let v = value * 1;
      if (isNaN(v)) {
        callback(new Error('请输入数字值'));
      } else {
        if (v < 0) {
          callback(new Error('必须大于0'));
        } else {
          callback();
        }
      }
    };
    return {
      filter: {},
      options: {
        aff: [],
      },
      dataList: [],
      dialog: {
        type: 'add',
        visible: false,
        btn: false,
        loading: false,
      },
      postData: {
        offerId: '',
        clickCount: '',
        installRate: '',
        vbaAff: '',
        ofAffList: [
          {
            ofAff: '',
            percent: 0,
          },
        ],
      },
      loading: {
        list: false,
      },
      total: null,
      pageParam: {
        page: 1,
        pageSize: 10,
      },
      rules: {
        clickCount: [
          {
            required: true,
            message: '不能为空！',
            trigger: 'blur',
          },
          {
            validator: validateNumber,
            trigger: 'blur',
          },
        ],
        installRate: [
          {
            required: true,
            message: '不能为空！',
            trigger: 'blur',
          },
          {
            validator: validateNumber,
            trigger: 'blur',
          },
        ],
        'ofAffList[0].percent': [
          {
            required: true,
            message: '不能为空！',
            trigger: 'blur',
          },
          {
            validator: validateNumber,
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {
    ...mapState('affiliate', {
      affiliatesGroupList: (state) => state.affiliatesGroupList,
      affiliateLoading: (state) => state.affiliateLoading,
      installCount() {
        return (value) => {
          if (
            value.clickCount !== undefined &&
            value.installRate !== undefined &&
            value.percent !== undefined
          ) {
            console.log(
              Number(value.clickCount) * Number(value.installRate) * Number(value.percent)
            );
            return Math.round(
              Number(value.clickCount) * Number(value.installRate) * Number(value.percent)
            );
          } else {
            return 0;
          }
        };
      },
    }),
  },
  created() {},
  mounted() {
    //获取Affiliate
    this.getAffiliateList();
    this.getDataList();
  },
  methods: {
    // get Affiliate
    ...mapActions('affiliate', ['getAffiliateList']),
    getDataList(curPage) {
      if (curPage) {
        this.pageParam.page = curPage;
      }
      let param = {
        ...this.filter,
        page: this.pageParam.page,
        pageSize: this.pageParam.pageSize,
      };
      this.loading.list = true;
      fetchBidOfferAffMixPage(param).then((response) => {
        if (response.code == 200) {
          this.dataList = response.result;
          this.total = response.total;
          this.loading.list = false;
        } else {
          this.loading.list = false;
          this.$message.error(response.status.msg);
        }
      });
    },
    // 操作方法
    handleFun(type, index) {
      // 自定义校验方法
      var validateNumber2 = (rule, value, callback) => {
        let v = value * 1;
        if (isNaN(v)) {
          callback(new Error('请输入数字值'));
        } else {
          if (v < 0) {
            callback(new Error('必须大于0'));
          } else {
            callback();
          }
        }
      };
      switch (type) {
        // 添加操作
        case 'add':
          // 校验参数
          var percentRule = [
            {
              required: true,
              message: '不能为空！',
              trigger: 'blur',
            },
            {
              validator: validateNumber2,
              trigger: 'blur',
            },
          ];
          // 动态添加rule
          this.rules[`ofAffList[${this.postData.ofAffList.length}].percent`] = percentRule;
          // 动态添加数据
          this.postData.ofAffList.push({
            ofAff: '',
            percent: 0,
          });
          break;
        // 删除操作
        case 'delete':
          // 删除数据
          this.postData.ofAffList.splice(index, 1);
          // 删除校验
          delete this.rules[`ofAffList[${index}].percent`];
          break;
        default:
          break;
      }
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return;
        this.dialog.btn = true;
        if (this.dialog.type == 'add') {
          handleBidOfferAffMixAdd(this.postData).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.message);
              this.reset(formName);
              this.getDataList();
              this.dialog.visible = false;
            } else {
              this.$message.error(res.message);
            }
            this.dialog.btn = false;
          });
        }
        if (this.dialog.type == 'edit') {
          handleBidOfferAffMixEdit(this.postData).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.$message.success(res.message);
              this.reset(formName);
              this.getDataList();
              this.dialog.visible = false;
            } else {
              this.$message.error(res.message);
            }
            this.dialog.btn = false;
          });
        }
      });
    },
    deletes(row) {
      this.$confirm('Are you sure?', 'Tips', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning',
      })
        .then(() => {
          handleBidOfferAffMixDel(row.id).then((res) => {
            this.$message({
              type: 'success',
              message: res.message,
            });
            this.getDataList();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Deletion cancelled!',
          });
        });
      console.log(row.id);
    },
    open(row) {
      if (row) {
        this.dialog.type = 'edit';
        this.dialog.loading = true;
        fetchBidOfferAffMixDetails(row.id).then((res) => {
          this.dialog.loading = false;
          this.postData = res.result;
          this.postData.ofAffList = JSON.parse(res.result.ofAffList);
          // 自定义校验方法
          var validateNumber2 = (rule, value, callback) => {
            let v = value * 1;
            if (isNaN(v)) {
              callback(new Error('请输入数字值'));
            } else {
              if (v < 0) {
                callback(new Error('必须大于0'));
              } else {
                callback();
              }
            }
          };
          // 校验参数
          var percentRule = [
            {
              required: true,
              message: '不能为空！',
              trigger: 'blur',
            },
            {
              validator: validateNumber2,
              trigger: 'blur',
            },
          ];
          for (var i = 1; i < this.postData.ofAffList.length; i++) {
            // 动态添加rule
            this.rules[`ofAffList[${i}].percent`] = percentRule;
          }
        });
      } else {
        this.dialog.type = 'add';
      }
      this.dialog.visible = true;
    },
    close(formName) {
      this.dialog.visible = false;
      this.reset(formName);
    },
    /**
     * 重置表单
     * @param {*} formName
     */
    async reset(formName) {
      this.$refs[formName].resetFields();
    },
    dialogClosed() {
      this.postData = {};
      this.$set(this.postData, 'ofAffList', []);
      this.postData.ofAffList.push({
        ofAff: '',
        percent: 0,
      });
    },
    handleSizeChange(size) {
      this.pageParam.pageSize = size;
      this.handleCurrentChange(1);
    },
    async handleCurrentChange(currentPage) {
      this.pageParam.page = currentPage;
      this.getDataList();
    },
  },
};
