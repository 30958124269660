<template>
  <div class="smartLink">
    <!--    <div class="main-Title bgff">
      <h2>Bid Offer Aff Mix</h2>
    </div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form v-model="filter" size="mini" label-width="80px">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label="offer id" label-width="60px" class="mb0">
                <el-input v-model="filter.offerId" placeholder="offer id here..."></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label="affiliate id" class="mb0">
                <group-select
                  v-model="filter.queryAff"
                  :data-source="affiliatesGroupList"
                  :loading="affiliateLoading"
                  clearable
                  reserve-keyword
                  filterable
                  placeholder="Please select Affiliate"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label-width="0px" class="mb0">
                <el-button type="primary" @click="getDataList(1)" :loading="loading.list"
                  >Search</el-button
                >
                <el-button type="primary" @click="open()">Create</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          class="w100"
          stripe
          border
          :data="dataList"
          highlight-current-row
          size="mini"
        >
          <el-table-column label="offer id" prop="offerId" align="center"></el-table-column>
          <el-table-column label="offer name" prop="offerName" align="center"></el-table-column>
          <el-table-column label="package" prop="appPkg" align="center"></el-table-column>
          <el-table-column label="manager" prop="manager" align="center"></el-table-column>
          <el-table-column label="vba" prop="vbaAff" align="center"></el-table-column>
          <el-table-column label="click count" prop="clickCount" align="center"></el-table-column>
          <el-table-column label="of" prop="ofAffList" align="center">
            <template slot-scope="scope">
              <div v-for="(item, index) in JSON.parse(scope.row.ofAffList)" :key="index">
                {{ item.ofAff }} : {{ item.percent }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="CVR" prop="installRate" align="center"></el-table-column>
          <el-table-column label="operation" prop="" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="open(scope.row)">update</el-button>
              <el-button type="text" size="mini" @click="deletes(scope.row)">delete</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="align-c">
          <pagination
            class="block pagePanel"
            :pageTotal="total"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :pageSize="pageParam.pageSize"
            :currentPage="pageParam.page"
          ></pagination>
        </div>
      </el-card>
      <!-- 添加模态框 -->
      <el-dialog
        :visible.sync="dialog.visible"
        :close-on-click-modal="false"
        :append-to-body="true"
        @closed="dialogClosed('postData')"
        title="渠道参数"
      >
        <el-form
          :model="postData"
          ref="postData"
          label-width="100px"
          :rules="rules"
          v-loading="dialog.loading"
        >
          <el-form-item label="offer id:" prop="offerId">
            <el-input
              v-model="postData.offerId"
              :disabled="dialog.type == 'edit'"
              placeholder="offer id here..."
            ></el-input>
          </el-form-item>
          <el-form-item label="click count:" prop="clickCount">
            <el-input v-model="postData.clickCount" placeholder="click count here..."></el-input>
          </el-form-item>
          <el-form-item label="CVR:" prop="installRate">
            <el-input v-model="postData.installRate" placeholder="CVR here..."></el-input>
          </el-form-item>
          <el-form-item label="VBA:" prop="vbaAff">
            <group-select
              v-model="postData.vbaAff"
              :data-source="affiliatesGroupList"
              :loading="affiliateLoading"
              clearable
              reserve-keyword
              filterable
              placeholder="Please select Affiliate"
              class="w100"
            />
          </el-form-item>
          <el-form-item label="OF:" prop="ofAffList">
            <el-row v-for="(item, index) in postData.ofAffList" :key="index" class="mb10">
              <el-col :span="6">
                <el-form-item label="" label-width="0">
                  <group-select
                    v-model="item.ofAff"
                    :data-source="affiliatesGroupList"
                    :loading="affiliateLoading"
                    clearable
                    reserve-keyword
                    filterable
                    placeholder="Please select Affiliate"
                    class="w100"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="percentage" :prop="`ofAffList[${index}].percent`">
                  <el-input
                    v-model="postData.ofAffList[index].percent"
                    placeholder="percentage here..."
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="install count">
                  <el-input
                    disabled
                    :value="
                      installCount({
                        clickCount: postData.clickCount,
                        installRate: postData.installRate,
                        percent: item.percent,
                      })
                    "
                    placeholder="install count here..."
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4" class="pl10">
                <el-button type="primary" size="small" @click="handleFun('add')" v-if="index == 0"
                  >Add</el-button
                >
                <el-button
                  type="danger"
                  size="small"
                  @click="handleFun('delete', index)"
                  v-if="index > 0"
                  >delete</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>
          <div class="align-c">
            <el-button @click="close('postData')" size="mini" :loading="dialog.btn"
              >取 消</el-button
            >
            <el-button type="primary" size="mini" @click="submit('postData')" :loading="dialog.btn"
              >确 定</el-button
            >
          </div>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import bidOfferAffMixCon from '../controllers/product/bidOfferAffMix';

  export default {
    name: 'BidOfferAffMix',
    ...bidOfferAffMixCon,
  };
</script>
<style lang="scss" scoped></style>
