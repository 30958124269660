import request from 'utils/request';

const api = {
  BIDOFFERAFFMIX_PAGE: '/api/bidOfferAffMix/list',
  BIDOFFERAFFMIX_ADD: '/api/bidOfferAffMix/add',
  BIDOFFERAFFMIX_UPDATE: '/api/bidOfferAffMix/update',
  BIDOFFERAFFMIX_DEL: '/api/bidOfferAffMix/delete/',
  BIDOFFERAFFMIX_DETAILS: '/api/bidOfferAffMix/selectById/',
};

export function fetchBidOfferAffMixPage(params) {
  return request({
    url: api.BIDOFFERAFFMIX_PAGE,
    method: 'get',
    params,
  });
}

// 添加配置
export function handleBidOfferAffMixAdd(data) {
  return request({
    url: api.BIDOFFERAFFMIX_ADD,
    method: 'post',
    data,
  });
}

// 修改配置
export function handleBidOfferAffMixEdit(data) {
  return request({
    url: api.BIDOFFERAFFMIX_UPDATE,
    method: 'post',
    data,
  });
}

// 删除配置
export function handleBidOfferAffMixDel(data) {
  return request({
    url: api.BIDOFFERAFFMIX_DEL + data,
    method: 'post',
  });
}

export function fetchBidOfferAffMixDetails(params) {
  return request({
    url: api.BIDOFFERAFFMIX_DETAILS + params,
    method: 'get',
  });
}
